<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">{{ headline }}</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자(시작)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자(종료)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder :disabled="!filter.searchKey" dense hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" rounded text @click="onClickSearch"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table v-bind="{ ...attrs_table, items, headers }">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.no`]="{ item }"> {{ summary.totalCount - (page - 1) * limit - forms.indexOf(item) }} </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon v-if="!!item.reply" small @click="email(item)" title="이메일">mdi-email</v-icon>
                    <v-icon small @click="edit(item)" title="수정" class="ml-2">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

const headers = [
    { align: "center", text: "no", value: "no" },
    { align: "center", text: "후원유형", value: "code" },
    { align: "center", text: "작성자", value: "primary.name" },
    { align: "center", text: "연락처", value: "primary.phone" },
    // { align: "center", text: "이메일", value: "primary.email" },
    { align: "center", text: "작성일", value: "createdAt", formatter: (value) => value.toDateTime() },
    { align: "center", text: "답변이메일전송일", value: "emailedAt", formatter: (value) => value?.toDateTime?.() || "-", code: ["consignment-sale"] },
    { align: "right", text: "", value: "actions" },
];

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            forms: [],

            searchKeys: [
                { text: "전체", value: null },
                { text: "후원유형", value: "code" },
                { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    computed: {
        type() {
            return this.$route.query.type;
        },
        headline() {
            if (this.type == "children") return "아동후원 폼메일 목록";
            if (this.type == "products") return " 물품후원 폼메일 목록";
            if (this.type == "news") return "소식받기 폼메일 목록";
            return "폼메일 목록";
        },
        items() {
            return this.forms;
        },
        headers() {
            return headers.filter(({ code, code_exclude } = {}) => {
                if (code && !code.includes(this.code)) return false;
                if (code_exclude && code_exclude.includes(this.code)) return false;
                return true;
            });
        },
        attrs_table() {
            return {
                "disable-sort": true,
                "disable-pagination": true,
                "hide-default-footer": true,
                class: "elevation-1 mt-4",
            };
        },
    },
    mounted() {
        this.init();
        const codeParam = this.$route.query.code;
        if (codeParam) {
            this.filter.code = codeParam;
        }
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, forms } = await api.console.forms.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.forms = forms;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(form) {
            this.$router.push(`/console/forms/${form._id}`);
        },
        async email(form) {
            try {
                if (confirm(`${form.isEmailed ? "답변 이메일 전송 이력이 있습니다.\n계속해서 " : ""}답변 이메일을 전송하시겠습니까?`)) {
                    await api.console.forms.email(form);
                    await this.search(false);

                    alert("전송되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove(form) {
            try {
                if (confirm("폼메일을 삭제하시겠습니까?")) {
                    await api.console.forms.delete(form);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        onClickSearch() {
            this.page = 1;
            this.search();
        },
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
};
</script>
