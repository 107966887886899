var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-1",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm.user._id ? _c('span', [_vm._v("회원 상세보기")]) : _c('span', [_vm._v("회원 등록")])]), _c('v-card', {
    staticClass: "my-4"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("계정")]), _c('v-divider'), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  })], 1), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  })], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("기본 정보")]), _c('v-divider'), _vm.user.type == _vm.PERSON.value ? [_c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "직급",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.occupation,
      callback: function ($$v) {
        _vm.$set(_vm.user, "occupation", $$v);
      },
      expression: "user.occupation"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기관명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.user, "companyName", $$v);
      },
      expression: "user.companyName"
    }
  })], 1)], 1)] : _vm._e(), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("연락처")]), _c('v-divider'), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1)], 1), _c('v-divider')], 2), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "d-none d-md-block",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "d-block d-md-none"
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "align-center grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")]), _c('user-withdraw', {
    on: {
      "input": function ($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "color": "red darken-1",
            "dark": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("탈퇴")])];
      }
    }]),
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mx-1 my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  })], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": _vm.setAddress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }