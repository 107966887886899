var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-support-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._support ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("제목")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.support.subject,
      callback: function ($$v) {
        _vm.$set(_vm.support, "subject", $$v);
      },
      expression: "support.subject"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.support.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.support.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "썸네일 이미지",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.support.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.support, "thumb", $$v);
      },
      expression: "support.thumb"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("아동 기본정보")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-card-subtitle', [_vm._v("이미지(793*793)")]), _c('v-divider'), _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-card-text', [_vm.imgSrcs.length ? _c('v-tabs-items', {
    attrs: {
      "value": _vm.image_selected
    }
  }, _vm._l(_vm.imgSrcs, function (src) {
    return _c('v-tab-item', {
      key: src
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('work-imgs-overlay', {
      attrs: {
        "files": _vm.imgSrcs.map(function (url) {
          return {
            url
          };
        }),
        "disableLink": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on,
            setIndex = _ref.setIndex;
          return [_c('v-img', _vm._g(_vm._b({
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "src": src,
              "width": "100%",
              "aspect-ratio": 1 / 1
            },
            on: {
              "click": function ($event) {
                return setIndex({
                  url: src
                });
              }
            }
          }, 'v-img', attrs, false), on))];
        }
      }], null, true)
    })], 1)], 1);
  }), 1) : _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "0",
      "color": "grey darken-1"
    }
  }, [_vm._v("이미지가 없습니다")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('images-input', {
    on: {
      "mouseover": _vm.setImage_selected
    },
    model: {
      value: _vm.support.files,
      callback: function ($$v) {
        _vm.$set(_vm.support, "files", $$v);
      },
      expression: "support.files"
    }
  })], 1)], 1), _c('v-col', [_c('v-card-text', {
    staticClass: "pb-6"
  }, [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card-subtitle', [_vm._v("* 이름 :")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    model: {
      value: _vm.support.child.name,
      callback: function ($$v) {
        _vm.$set(_vm.support.child, "name", $$v);
      },
      expression: "support.child.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card-subtitle', [_vm._v("* 성별 :")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    model: {
      value: _vm.support.child.sex,
      callback: function ($$v) {
        _vm.$set(_vm.support.child, "sex", $$v);
      },
      expression: "support.child.sex"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card-subtitle', [_vm._v("* 생년월일 :")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    model: {
      value: _vm.support.child.birthDay,
      callback: function ($$v) {
        _vm.$set(_vm.support.child, "birthDay", $$v);
      },
      expression: "support.child.birthDay"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card-subtitle', [_vm._v("* 가족사항 :")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    model: {
      value: _vm.support.child.family,
      callback: function ($$v) {
        _vm.$set(_vm.support.child, "family", $$v);
      },
      expression: "support.child.family"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card-subtitle', [_vm._v("* 건강상태 :")])], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    model: {
      value: _vm.support.child.health,
      callback: function ($$v) {
        _vm.$set(_vm.support.child, "health", $$v);
      },
      expression: "support.child.health"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("상세내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    model: {
      value: _vm.support.content,
      callback: function ($$v) {
        _vm.$set(_vm.support, "content", $$v);
      },
      expression: "support.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }