<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3">
            <div class="headline text-start mt-8">{{ headline }}</div>
            <v-card class="mt-6">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row v-if="$route.params._form && form.code === '소식받기'">
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.news.name" label="작성자" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly disabled />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="form.news.phone" label="연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="form.news.email" label="이메일" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                    <v-row v-if="$route.params._form && form.code === '물품후원'">
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.product.manager" label="담당자" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.product.companyName" label="회사명" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.product.departmentName" label="부서" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly disabled />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="form.product.phone" label="연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="form.product.email" label="이메일" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                    <v-row v-if="$route.params._form && form.code !== '물품후원' && form.code !== '소식받기'">
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.primary.name" label="성명(단체명)" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.primary.recommender" label="추천인" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="`${form.primary.residentNum1}-${form.primary.residentNum2}`" label="주민등록번호" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly disabled />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="form.primary.phone" label="연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="form.primary.address" label="주소" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.primary.tax === 'true' ? '신청' : '신청안함'" label="소득공제 영수증" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="$route.params._form && form.code === '소식받기'">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea v-model="form.news.contents" hide-details dense auto-grow />
                </v-card-text>
            </v-card>
            <v-card class="mt-6" v-if="$route.params._form && form.code === '물품후원'">
                <v-card-title class="subtitle-1">문의내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea v-model="form.product.inquiry" hide-details dense auto-grow />
                </v-card-text>
            </v-card>
            <v-card v-if="$route.params._form && form.code !== '물품후원' && form.code !== '소식받기'">
                <v-card-title class="subtitle-1">후원방법</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row>
                        <v-col v-if="form.type" cols="6">
                            <v-text-field :value="form.type" label="캠페인" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :value="form.sponsor.bank" label="은행명" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :value="form.sponsor.accountHolder" label="예금주" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :value="form.sponsor.accountNum" label="계좌번호" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="form.sponsor.birth" label="예금주 생년월일" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="form.sponsor.phone" label="예금주 연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="form.sponsor.amount.support" label="약정금액" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" v-if="form.sponsor.amount.support === '기타'">
                            <v-text-field v-model="form.sponsor.amount.priceEtc" label="기타약정금액" suffix="만원" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="form.sponsor.amount.day" label="출금일" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" v-if="form.sponsor.amount.day === '기타'">
                            <v-text-field v-model="form.sponsor.amount.dayEtc" label="기타출금일" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="form.sponsor.memo" label="기타 요청사항" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- <v-card class="mt-6">
                <v-card-title class="subtitle-1">답변</v-card-title>
                <v-divider />
                <v-card-text>
                    <naver-smarteditor v-model="form.reply" id="reply" rows="10"></naver-smarteditor>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn outlined color="grey" @click="form.reply = null">초기화</v-btn>
                    <v-spacer />
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-card-actions>
            </v-card> -->

            <!-- <v-row justify="center" v-if="!isCode(['interior-inquire', 'consignment-sale', 'private-sale'])">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row> -->
        </v-responsive>
    </v-layout>
</template>

<script>
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import WorkImgsOverlay from "@/components/console/auction/work-imgs-overlay.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import api from "@/api";
import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        ImagePopup,
        WorkImgsOverlay,
        NaverSmarteditor,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                type: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
            },
        };
    },
    computed: {
        headline() {
            return "폼메일 상세";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    var { form } = await api.console.forms.get({ _id: this.$route.params._form });
                    this.form = form;

                    console.log(this.form, "form");

                    if (form.thumb) this.form.thumb = await api.getResource(form.thumb);
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { form } = this.form._id ? await api.console.forms.put(this.form) : await api.console.forms.post(this.form);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (this.form.thumb) await api.console.forms.postThumb(form, this.form.thumb);

                alert(this.form._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        isCode(codes = []) {
            return codes.includes(this.form.code);
        },
    },
};
</script>
