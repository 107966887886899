var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.headline))]), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form && _vm.form.code === '소식받기' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.news.name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.news.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.news, "phone", $$v);
      },
      expression: "form.news.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.news.email,
      callback: function ($$v) {
        _vm.$set(_vm.form.news, "email", $$v);
      },
      expression: "form.news.email"
    }
  })], 1)], 1) : _vm._e(), _vm.$route.params._form && _vm.form.code === '물품후원' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.product.manager,
      "label": "담당자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.product.companyName,
      "label": "회사명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.product.departmentName,
      "label": "부서",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.product.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.product, "phone", $$v);
      },
      expression: "form.product.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.product.email,
      callback: function ($$v) {
        _vm.$set(_vm.form.product, "email", $$v);
      },
      expression: "form.product.email"
    }
  })], 1)], 1) : _vm._e(), _vm.$route.params._form && _vm.form.code !== '물품후원' && _vm.form.code !== '소식받기' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.primary.name,
      "label": "성명(단체명)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.primary.recommender,
      "label": "추천인",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": `${_vm.form.primary.residentNum1}-${_vm.form.primary.residentNum2}`,
      "label": "주민등록번호",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.primary.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.primary, "phone", $$v);
      },
      expression: "form.primary.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "주소",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.primary.address,
      callback: function ($$v) {
        _vm.$set(_vm.form.primary, "address", $$v);
      },
      expression: "form.primary.address"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.primary.tax === 'true' ? '신청' : '신청안함',
      "label": "소득공제 영수증",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _vm.$route.params._form && _vm.form.code === '소식받기' ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.news.contents,
      callback: function ($$v) {
        _vm.$set(_vm.form.news, "contents", $$v);
      },
      expression: "form.news.contents"
    }
  })], 1)], 1) : _vm._e(), _vm.$route.params._form && _vm.form.code === '물품후원' ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("문의내용")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.product.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.form.product, "inquiry", $$v);
      },
      expression: "form.product.inquiry"
    }
  })], 1)], 1) : _vm._e(), _vm.$route.params._form && _vm.form.code !== '물품후원' && _vm.form.code !== '소식받기' ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("후원방법")]), _c('v-divider'), _c('v-card-text', [_c('v-row', [_vm.form.type ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.type,
      "label": "캠페인",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.sponsor.bank,
      "label": "은행명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.sponsor.accountHolder,
      "label": "예금주",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.sponsor.accountNum,
      "label": "계좌번호",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "예금주 생년월일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sponsor.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form.sponsor, "birth", $$v);
      },
      expression: "form.sponsor.birth"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "예금주 연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sponsor.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.sponsor, "phone", $$v);
      },
      expression: "form.sponsor.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "약정금액",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sponsor.amount.support,
      callback: function ($$v) {
        _vm.$set(_vm.form.sponsor.amount, "support", $$v);
      },
      expression: "form.sponsor.amount.support"
    }
  })], 1), _vm.form.sponsor.amount.support === '기타' ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기타약정금액",
      "suffix": "만원",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sponsor.amount.priceEtc,
      callback: function ($$v) {
        _vm.$set(_vm.form.sponsor.amount, "priceEtc", $$v);
      },
      expression: "form.sponsor.amount.priceEtc"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "출금일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sponsor.amount.day,
      callback: function ($$v) {
        _vm.$set(_vm.form.sponsor.amount, "day", $$v);
      },
      expression: "form.sponsor.amount.day"
    }
  })], 1), _vm.form.sponsor.amount.day === '기타' ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기타출금일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sponsor.amount.dayEtc,
      callback: function ($$v) {
        _vm.$set(_vm.form.sponsor.amount, "dayEtc", $$v);
      },
      expression: "form.sponsor.amount.dayEtc"
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "기타 요청사항",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sponsor.memo,
      callback: function ($$v) {
        _vm.$set(_vm.form.sponsor, "memo", $$v);
      },
      expression: "form.sponsor.memo"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }