<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-support-view">
            <div v-if="!$route.query._campaign" class="headline text-start mt-8">게시글 등록</div>
            <div v-else class="headline text-start mt-8">게시글 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">제목</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="support.subject" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기간</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="support.periodStartDate" label="캠페인 시작일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="support.periodEndDate" label="캠페인 종료일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">한줄내용</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="support.summary" persistent-placeholder outlined full-width></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="support.thumb" max-width="96" max-height="96" :src="createObjectURL(support.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="support.thumb" label="썸네일 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">첨부파일1</v-card-title>
                <v-card-text>
                    <v-file-input v-model="support.files[0]" show-size />
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1">첨부파일2</v-card-title>
                <v-card-text>
                    <v-file-input v-model="support.files[1]" show-size />
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1">첨부파일3</v-card-title>
                <v-card-text>
                    <v-file-input v-model="support.files[2]" show-size />
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">상세내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="support.content"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";
import WorkImgsOverlay from "@/components/console/auction/work-imgs-overlay.vue";

import draggable from "vuedraggable";

import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
        ImagesInput,
        draggable,
        WorkImgsOverlay,
        VDateField
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            support: {
                code: this.$route.query.code,

                subject: undefined,
                content: undefined,

                thumb: undefined,
                files: [],

                child: {
                    name: null,
                    sex: null,
                    birthDay: null,
                    family: null,
                    health: null,
                }
            },

            image_selected: null

        };
    },
    mounted() {
        this.init();
    },
    computed:{
        imgSrcs() {
            if(this.support.files == undefined) this.support.files = []
            return this.support.files.map((file) => URL.createObjectURL(file));
        },
    },
    methods: {
        async init() {
            console.log(this.$route.params);
            try {
                if (this.$route.params._campaign !== 'create') {
                    let { support} = await api.console.supports.get({ _id: this.$route.params._campaign });
                    support.files = await Promise.all((support.files || []).map(async ({ url }) => await api.getResource(url, true)));
                    if (support.thumb) support.thumb = await api.getResource(support.thumb, true);
                    this.support = support;
                    console.log(this.support);
                }

                // if (!!this.$route.params._campaign) {
                //     var { summary } = await api.console.supports.gets({
                //         headers: { skip: 0, limit: 1 },
                //         params: {
                //             code: this.$route.query.code,
                //         },
                //     });
                //     this.support.sequence = summary.totalCount + 1;
                // }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _files = [], files = [], thumb, ...support } = this.support;
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 삭제
                ////////////////////////////////////////////////////////////////
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.console.supports.files.delete({ _support: support?._id, _id })));

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ support }] = [support._id ? await api.console.supports.put(support) : await api.console.supports.post(support)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.supports.postThumb(support, thumb);
                if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.console.supports.files.post({ _support: support?._id, index }, file)));

                alert(this.support._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        setImage_selected(file) {
            const index = this.support.files.findIndex((img) => img == file);
            this.image_selected = index;
        },
        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
    watch: {
        "support.files.length"() {
            this.image_selected = this.support?.files?.length - 1;
        },
    },
};
</script>
